import Component from "gia/Component";
import eventbus from "gia/eventbus";
import config from "gia/config";

import { toggle_mod } from "../../utilities/toggle_mod";
import { create_refs } from "../../utilities/create_refs";
import { create_main } from "../../utilities/create_main";

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

class event extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {
      button_open__inner: null,
      meta: null,
      content: null,
    };
    //////
    this.options = {
      blockName: "event",
      //////
      id: "defaultValue",
      category: "defaultValue",
    };
  }

  mount() {
    config.set("log", false);

    const options = this.options;
    const ref = this.ref;

    /////////////////////////////////
    // Config. main- & ref-objects //
    /////////////////////////////////

    this.setState({
      initialised: false,
      //////
      main: create_main(this.element, options.blockName),
      refs: create_refs(ref, options.blockName, false),
      //////
      active: false,
    });

    //////////////////////////
    // Config. interactions //
    //////////////////////////

    const state = this.state;
    const main = state.main;
    const refs = state.refs;

    const meta = ref.meta;
    meta.addEventListener("click", () => {
      if (this.state.active) {
        this.setState({
          active: false,
        });
      } else {
        this.setState({
          active: true,
        });
      }
    });

    ////////////////////////////////
    // Config. eventbus responses //
    ////////////////////////////////

    eventbus.on("filter_events", (args) => {
      const filter = args.filter;
      if (filter === "all" || options.category === filter) {
        this.setState({
          hidden: false,
        });
      } else {
        this.setState({
          hidden: true,
        });
      }
    });

    /////////////////
    // Init. comp. //
    /////////////////

    this.setState({
      initialised: true,
    });
  }

  ////////////////////////////////////
  // Config. state-change responses //
  ////////////////////////////////////

  stateChange(changes) {
    const state = this.state;
    const options = this.options;

    const main = state.main;
    const refs = state.refs;

    const blockName = options.blockName;

    /////////////////////////////////
    /////////////////////////////////

    if ("hidden" in changes) {
      if (state.initialised) {
        if (state.hidden) {
          main.hide();
        } else {
          main.show();
        }
      }
    }

    /////////////////////////////////
    /////////////////////////////////

    if ("active" in changes) {
      if (state.initialised) {
        const active = state.active;
        //////
        const meta = refs.find((ref) => ref.name_element === "meta");
        const content = refs.find((ref) => ref.name_element === "content");
        const button_open__inner = refs.find((ref) => ref.name_element === "button_open__inner");
        //////
        if (active) {
          meta.activate();
          button_open__inner.activate();
          content.show();
          eventbus.emit("event_show", { id: options.id });
        } else {
          meta.deactivate();
          button_open__inner.deactivate();
          content.hide();
        }
      }
    }
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
}

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

export { event };
