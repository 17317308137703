import Component from "gia/Component";
import eventbus from "gia/eventbus";
import config from "gia/config";

import { toggle_mod } from "../../utilities/toggle_mod";
import { create_refs } from "../../utilities/create_refs";
import { create_main } from "../../utilities/create_main";

import OverlayScrollbars from "overlayscrollbars";
import lozad from "lozad";

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

class scrollarea extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {};
    //////
    this.options = {
      blockName: "scrollarea",
    };
  }

  mount() {
    config.set("log", false);

    const options = this.options;
    const ref = this.ref;

    /////////////////////////////////
    // Config. main- & ref-objects //
    /////////////////////////////////

    this.setState({
      main: create_main(this.element, options.blockName),
      refs: create_refs(ref, options.blockName),
    });

    // setTimeout(() => {
    //   if (window.innerWidth < 750) {
    //     this.set_padding_mobile();
    //   }
    // }, 500);

    ////////////////////////////////////
    // Config + init. scrollbar-inst. //
    ////////////////////////////////////

    if (window.innerWidth > 750) {
      const inst_scrollbar = OverlayScrollbars(this.element, {
        className: "os_theme_studio21",
        //////
        callbacks: {
          onInitialized: () => {
            this.set_height(window.innerWidth < 750);
          },
          //////
          onScroll: () => {
            if (!this.state.shadow_active) {
              this.setState({
                shadow_active: true,
              });
            }
            //////
            const scrollInfo = inst_scrollbar.scroll();
            if (scrollInfo.ratio.y === 1) {
              this.setState({
                shadow_active: false,
              });
            }
          },
        },
      });
    }

    //////////////////////////
    // Config. interactions //
    //////////////////////////

    const state = this.state;
    const main = state.main;
    const refs = state.refs;

    ////////////////////////////////
    // Config. eventbus responses //
    ////////////////////////////////

    // eventbus.on("window_resize", () => {
    //   this.set_padding();
    // });
  }

  ////////////////////////////////////
  // Config. state-change responses //
  ////////////////////////////////////

  stateChange(changes) {
    const state = this.state;
    const options = this.options;

    const main = state.main;
    const refs = state.refs;

    const blockName = options.blockName;

    ////////////////////////////////////
    ////////////////////////////////////

    if ("shadow_active" in changes) {
      if (state.shadow_active) {
        main.element.classList.add(blockName + "_shadow_active");
      } else {
        main.element.classList.remove(blockName + "_shadow_active");
      }
    }
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  set_height(isMobile) {
    if (isMobile) {
      this.element.style.height = window.innerHeight + "px";
    }
  }

  ////////////////////////////////////
  ////////////////////////////////////

  set_padding() {
    const siteHeader_typo = document.querySelector(".site-title__letter_s");
    const siteHeader_typo_DOMrect = siteHeader_typo.getBoundingClientRect();
    //////
    this.element.style.paddingTop = siteHeader_typo_DOMrect.height + 10 + "px";
  }

  ////////////////////////////////////
  ////////////////////////////////////

  set_padding_mobile() {
    const siteHeader = document.querySelector(".mobile-site-title");
    console.log(siteHeader.offsetHeight);
    //////
    this.element.style.paddingTop = siteHeader.offsetHeight + "px";
  }
}

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

export { scrollarea };
