import Component from "gia/Component";
import eventbus from "gia/eventbus";
import config from "gia/config";

import { toggle_mod } from "../../../utilities/toggle_mod";
import { create_refs } from "../../../utilities/create_refs";
import { create_main } from "../../../utilities/create_main";

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

class frontpageContent__section extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {
      svgSection_bangPow: null,
      svg_bangPow: null,
      lettergroup_bangPow: null,
      ///
      svgSection_zackZackUmpf: null,
      svg_zackZackUmpf: null,
      lettergroup_zackZackUmpf: null,
    };
    //////
    this.options = {
      blockName: "frontpage-content",
    };
  }

  mount() {
    config.set("log", false);

    const options = this.options;
    const ref = this.ref;

    /////////////////////////////////
    // Config. main- & ref-objects //
    /////////////////////////////////

    this.setState({
      main: create_main(this.element, options.blockName),
      refs: create_refs(ref, options.blockName, true),
    });

    ////////////////////////////////
    // Config. section dimensions //
    ////////////////////////////////

    setTimeout(() => {
      this.set_sectionHeight();
    }, 1500);

    ///////////////////////////
    // Position lettergroups //
    ///////////////////////////

    const svgSection_bangPow = ref.svgSection_bangPow;
    const svg_bangPow = ref.svg_bangPow;
    const lettergroup_bangPow = ref.lettergroup_bangPow;

    const svgSection_zackZackUmpf = ref.svgSection_zackZackUmpf;
    const svg_zackZackUmpf = ref.svg_zackZackUmpf;
    const lettergroup_zackZackUmpf = ref.lettergroup_zackZackUmpf;

    setTimeout(() => {
      this.svgSection_set_fixedDimensions(svgSection_bangPow);
      this.svgSection_set_fixedDimensions(svgSection_zackZackUmpf);

      this.position_lettergroup(svg_bangPow, lettergroup_bangPow);
      this.position_lettergroup(svg_zackZackUmpf, lettergroup_zackZackUmpf);
    }, 2000);
  }

  ////////////////////////////////////
  // Config. state-change responses //
  ////////////////////////////////////

  stateChange(changes) {
    const state = this.state;
    const options = this.options;

    const main = state.main;
    const refs = state.refs;

    const blockName = options.blockName;

    ////////////////////////////////////
    ////////////////////////////////////

    if ("height_max" in changes) {
      console.log(state.height_max);
    }
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  get_ref(title) {
    return this.state.refs.find((ref) => ref.name_element === title);
  }

  ////////////////////////////////////
  ////////////////////////////////////

  set_sectionHeight() {
    const section = this.element;
    const section_contentElements = section.querySelectorAll(".frontpage-content__section-content");
    //////
    let section_height_max = 0;
    //////
    for (let contentElement of section_contentElements) {
      const contentElement_height = contentElement.offsetHeight;
      if (contentElement_height > section_height_max) {
        section_height_max = contentElement_height;
      }
    }
    //////
    section.style.maxHeight = section_height_max + "px";
    //////
    this.setState({
      height_max: section_height_max,
    });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  svgSection_set_fixedDimensions(svgSection) {
    const svgSection_width = svgSection.offsetWidth;
    const svgSection_height = svgSection.offsetHeight;
    //////
    svgSection.style.maxWidth = svgSection_width + "px";
    svgSection.style.maxHeight = svgSection_height + "px";
  }

  ////////////////////////////////////
  ////////////////////////////////////

  position_lettergroup(svgParent, lettergroup) {
    const viewbox = svgParent.getBoundingClientRect();
    const lettergroup_DOMrect = lettergroup.getBoundingClientRect();
    //////
    const offset_top = lettergroup_DOMrect.top - viewbox.top - 7;
    //////
    svgParent.style.marginTop = "-" + offset_top + "px";
    // section_content.style.marginTop = "-" + offset_top + "px";
    //////
    this.resize_lettergroup(svgParent, lettergroup);
  }

  ////////////////////////////////////
  ////////////////////////////////////

  resize_lettergroup(svgParent, lettergroup) {
    const viewbox = svgParent.getBoundingClientRect();
    const lettergroup_DOMrect = lettergroup.getBoundingClientRect();
    //////
    const offset_left = lettergroup_DOMrect.left - viewbox.left - 7;
    //////
    svgParent.style.width = viewbox.width + offset_left * 2 + "px";
    svgParent.style.transform = "translateX(-" + offset_left + "px)";
  }

  ////////////////////////////////////
  ////////////////////////////////////

  create_marker(element, color) {
    const element_DOMrect = element.getBoundingClientRect();
    //////
    const marker = document.createElement("div");
    marker.setAttribute("class", "marker");
    marker.setAttribute(
      "style",
      "height: " +
        element_DOMrect.height +
        "px; width: " +
        element_DOMrect.width +
        "px; top: " +
        element_DOMrect.top +
        "px; left: " +
        element_DOMrect.left +
        "px;"
    );
    //////
    if (color != undefined && color != null) {
      marker.style.borderColor = color;
    }
    //////
    document.body.appendChild(marker);
  }
}

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

export { frontpageContent__section };
