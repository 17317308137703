import Component from "gia/Component";
import eventbus from "gia/eventbus";
import config from "gia/config";

import { toggle_mod } from "../../utilities/toggle_mod";
import { create_refs } from "../../utilities/create_refs";
import { create_main } from "../../utilities/create_main";

import { getRandomInt } from "../../utilities/get_randomInt";

import anime from "animejs/lib/anime.es.js";

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

class siteTitle extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {
      typo: null,
      lettergroup: null,
      letter_s: null,
      letter_t: null,
      letter_u: null,
      letter_d: null,
      letter_i: null,
      letter_o: null,
      letter_2: null,
      letter_1: null,
    };
    //////
    this.options = {
      blockName: "site-title",
      //////
      animated: "defaultValue",
      overlay: "defaultValue",
    };
  }

  mount() {
    config.set("log", false);

    const options = this.options;
    const ref = this.ref;

    /////////////////////////////////
    // Config. main- & ref-objects //
    // + letter-elements           //
    /////////////////////////////////

    this.setState({
      main: create_main(this.element, options.blockName),
      refs: create_refs(ref, options.blockName, true),
      //////
      letters: [document.querySelectorAll(".frontpage-content__svg-letter")],
    });

    ///////////////////
    // Position typo //
    ///////////////////

    this.typo_position();

    ///////////////////////////////
    // Config. letter-animations //
    ///////////////////////////////

    if (window.innerWidth > 700 && options.animated === "true" && options.overlay === "false") {
      setTimeout(() => {
        this.typo_animation_0();
      }, 2000);
    }

    //////////////////////////
    // Config. interactions //
    //////////////////////////

    const state = this.state;
    const main = state.main;
    const refs = state.refs;

    ////////////////////////////////
    // Config. eventbus responses //
    ////////////////////////////////
  }

  ////////////////////////////////////
  // Config. state-change responses //
  ////////////////////////////////////

  stateChange(changes) {
    const state = this.state;
    const options = this.options;

    const main = state.main;
    const refs = state.refs;

    const blockName = options.blockName;

    ////////////////////////////////////
    ////////////////////////////////////
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  typo_position() {
    const typo = this.ref.typo;
    const letter_s = this.ref.letter_s;
    //////
    const typo_DOMRect = typo.getBoundingClientRect();
    const letter_s_DOMRect = letter_s.getBoundingClientRect();
    //////
    const letter_s_offset_left = letter_s_DOMRect.left;
    const letter_s_offset_top = (typo_DOMRect.height - letter_s_DOMRect.height) / 2;
    //////
    typo.style.left = (letter_s_offset_left - 10) * -1 + "px";
    typo.style.top = (letter_s_offset_top - 10) * -1 + "px";
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  typo_animation_0() {
    const ref = this.ref;

    const letter_s = ref.letter_s;
    const letter_t = ref.letter_t;
    const letter_u = ref.letter_u;
    const letter_d = ref.letter_d;
    const letter_i = ref.letter_i;
    const letter_o = ref.letter_o;
    const letter_2 = ref.letter_2;
    const letter_1 = ref.letter_1;

    const letters = [letter_s, letter_t, letter_u, letter_d, letter_i, letter_o, letter_2, letter_1];

    let value_delay_max = 0;

    for (let letter of letters) {
      //////////////////////////
      // Get random value for //
      // stroke-width & delay //
      //////////////////////////

      const value_strokeWidth = getRandomInt(20, 100);
      const value_delay = getRandomInt(0, 600);

      if (value_delay > value_delay_max) {
        value_delay_max = value_delay;
      }

      /////////////
      // Animate //
      /////////////

      anime({
        targets: letter,
        strokeWidth: value_strokeWidth + "px",
        //////
        delay: 0,
        endDelay: 1000,
        //////
        easing: "spring(1, 100, 4, 6)",
        //////
        complete: () => {
          if (letter === letters[letters.length - 1]) {
            this.typo_animation_0();
          }
        },
      });
    }
  }

  typo_animation_1() {
    const ref = this.ref;

    const letter_s = ref.letter_s;
    const letter_t = ref.letter_t;
    const letter_u = ref.letter_u;
    const letter_d = ref.letter_d;
    const letter_i = ref.letter_i;
    const letter_o = ref.letter_o;
    const letter_2 = ref.letter_2;
    const letter_1 = ref.letter_1;

    const letters = [letter_s, letter_t, letter_u, letter_d, letter_i, letter_o, letter_2, letter_1];

    let value_delay_max = 0;

    for (let letter of letters) {
      //////////////////////////
      // Get random value for //
      // stroke-width & delay //
      //////////////////////////

      const value_strokeWidth = getRandomInt(20, 100);
      const value_delay = getRandomInt(0, 600);

      if (value_delay > value_delay_max) {
        value_delay_max = value_delay;
      }

      /////////////
      // Animate //
      /////////////

      anime({
        targets: letter,
        strokeWidth: value_strokeWidth + "px",
        //////
        delay: value_delay,
        // direction: "alternate",
        endDelay: 600,
        //////
        easing: "spring(1, 100, 4, 6)",
        // easing: "cubicBezier(.5, .05, .1, .3)",
      });
    }
  }
}

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

export { siteTitle };
