import Component from "gia/Component";
import eventbus from "gia/eventbus";
import config from "gia/config";

import { toggle_mod } from "../../utilities/toggle_mod";
import { create_refs } from "../../utilities/create_refs";
import { create_main } from "../../utilities/create_main";

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

class nav extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {
      menu: null,
      //////
      activeLinkDisplay: null,
      activeLinkDisplay__label: null,
      //////
      button_toggle: null,
      button_toggle__inner: null,
      //////
      links: [],
    };
    //////
    this.options = {
      blockName: "nav",
    };
  }

  mount() {
    config.set("log", false);

    const options = this.options;
    const ref = this.ref;

    /////////////////////////////////
    // Config. main- & ref-objects //
    /////////////////////////////////

    this.setState({
      main: create_main(this.element, options.blockName),
      refs: create_refs(ref, options.blockName, false),
    });

    ///////////////////////////////////
    // Set offset from top of screen //
    ///////////////////////////////////

    // this.set_padding();

    //////////////////////////
    // Config. interactions //
    //////////////////////////

    const state = this.state;
    const main = state.main;
    const refs = state.refs;

    const links = ref.links;
    for (let link of links) {
      link.addEventListener("click", () => {
        this.setState({
          link_active: link.dataset.id,
          open: false,
        });
      });
    }

    ////////////////////////////////////
    ////////////////////////////////////

    const button_toggle = ref.button_toggle;
    button_toggle.addEventListener("click", () => {
      if (this.state.open) {
        this.setState({
          open: false,
        });
      } else {
        this.setState({
          open: true,
        });
      }
    });

    ////////////////////////////////
    // Config. eventbus responses //
    ////////////////////////////////

    eventbus.on("event", (args) => {});
  }

  ////////////////////////////////////
  // Config. state-change responses //
  ////////////////////////////////////

  stateChange(changes) {
    const state = this.state;
    const options = this.options;

    const main = state.main;
    const ref = this.ref;
    const refs = state.refs;

    const blockName = options.blockName;

    ////////////////////////////////////
    ////////////////////////////////////

    if ("link_active" in changes) {
      const link_active = state.link_active;
      const links = ref.links;
      // const activeLinkDisplay__label = ref.activeLinkDisplay__label;
      //////
      for (let link of links) {
        if (link.dataset.id === link_active) {
          link.classList.add(blockName + "__link_active");
          // activeLinkDisplay__label.querySelector("span").innerHTML = link_active;
        } else {
          link.classList.remove(blockName + "__link_active");
        }
      }
    }

    ////////////////////////////////////
    ////////////////////////////////////

    if ("open" in changes) {
      const button_toggle = this.get_ref("button_toggle");
      const button_toggle__inner = this.get_ref("button_toggle__inner");
      const menu = this.get_ref("menu");
      const activeLinkDisplay = this.get_ref("activeLinkDisplay");
      // const activeLinkDisplay__label = this.get_ref("activeLinkDisplay__label");
      //////
      if (state.open) {
        button_toggle.activate();
        button_toggle__inner.activate();
        activeLinkDisplay.activate();
        // activeLinkDisplay__label.hide();
        menu.activate();
        menu.show();
        //////
        eventbus.emit("nav_show");
      } else {
        button_toggle.deactivate();
        button_toggle__inner.deactivate();
        activeLinkDisplay.deactivate();
        // activeLinkDisplay__label.show();
        menu.deactivate();
        menu.hide();
        //////
        eventbus.emit("nav_hide");
      }
    }
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  get_ref(title) {
    return this.state.refs.find((ref) => ref.name_element === title);
  }

  ////////////////////////////////////
  ////////////////////////////////////

  set_padding() {
    const siteHeader_typo = document.querySelector(".site-title__letter_s");
    const siteHeader_typo_DOMrect = siteHeader_typo.getBoundingClientRect();
    //////
    this.element.style.paddingTop = siteHeader_typo_DOMrect.height + 10 + "px";
  }
}

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

export { nav };
