import Swup from "swup";
import SwupGiaPlugin from "@swup/gia-plugin";
import SwupPreloadPlugin from "@swup/preload-plugin";
import loadComponents from "gia/loadComponents";
import removeComponents from "gia/removeComponents";
import eventbus from "gia/eventbus";

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

///////////////
// App comp. //
///////////////

import { scrollarea } from "./components/scrollarea/scrollarea";
//////
import { events } from "./components/events/events";
import { event } from "./components/event/event";
import { event__slider } from "./components/event/slider/event__slider";
//////
import { slider_event } from "./components/slider/slider_event";
//////
import { frontpageContent__section } from "./components/frontpage-content/section/frontpageContent__section";

const appComponents = {
  scrollarea: scrollarea,
  //////
  events: events,
  event: event,
  event__slider: event__slider,
  //////
  slider_event: slider_event,
  //////
  frontpageContent__section: frontpageContent__section,
};

//////////////////
// Global comp. //
//////////////////

import { nav } from "./components/nav/nav";
// import { marquee } from "./components/marquee/marquee";
import { eventInfo } from "./components/event-info/event-info";
import { cover } from "./components/cover/cover";
import { siteTitle } from "./components/site-title/site-title";

loadComponents({
  nav: nav,
  // marquee: marquee,
  eventInfo: eventInfo,
  cover: cover,
  siteTitle: siteTitle,
});

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

////////////////
// Init. Swup //
////////////////

const swup = new Swup({
  plugins: [new SwupGiaPlugin({ components: appComponents, log: true }), new SwupPreloadPlugin()],
});

/////////////////////////
// Config. Swup events //
/////////////////////////

// swup.on("pageView", () => {
//   const namespace = document.body.querySelector("main").dataset.namespace;
//   eventbus.emit("swup_pageView", { namespace: namespace });
// });

swup.on("clickLink", () => {
  document.documentElement.scrollTop = 0;
});

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

///////////////////////////////////
// Config. global eventbus calls //
///////////////////////////////////

window.addEventListener("resize", () => {
  eventbus.emit("window_resize");
});

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

///////////////////////////////////
// Manually init. mobile marquee //
///////////////////////////////////

$(window).on("load", function () {
  if (window.innerWidth < 750) {
    const marquee_wrapper = document.querySelector(".mobile-marquee");
    const marquee_width = marquee_wrapper.offsetWidth;
    const marquee_height = marquee_wrapper.offsetHeight;
    //////
    $(".mobile-marquee__content").liMarquee({
      direction: "top",
      height: marquee_height,
      width: marquee_width,
      circular: true,
      scrollStop: false,
    });
  } else {
    const marquee_wrapper = document.querySelector(".marquee");
    const marquee_width = marquee_wrapper.offsetWidth;
    const marquee_height = marquee_wrapper.offsetHeight;
    //////
    $(".marquee__content").liMarquee({
      direction: "top",
      height: marquee_height,
      width: marquee_width,
      circular: true,
      scrollStop: false,
    });
  }
});
