import Component from "gia/Component";
import eventbus from "gia/eventbus";
import config from "gia/config";

import { toggle_mod } from "../../../utilities/toggle_mod";
import { create_refs } from "../../../utilities/create_refs";
import { create_main } from "../../../utilities/create_main";

import Swiper, { Navigation, Scrollbar } from "swiper/core";
Swiper.use([Navigation, Scrollbar]);

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

class event__slider extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {
      container: null,
      wrapper: null,
      controls: null,
      scrollbar: null,
      button_prev: null,
      button_next: null,
    };
    //////
    this.options = {
      blockName: "event__slider",
      //////
      id: "defaultValue",
      speed: 300,
    };
  }

  mount() {
    config.set("log", false);

    const options = this.options;
    const ref = this.ref;

    /////////////////////////////////
    // Config. main- & ref-objects //
    /////////////////////////////////

    this.setState({
      main: create_main(this.element, options.blockName),
      refs: create_refs(ref, options.blockName, true),
    });

    ////////////////////////
    // Build slider-inst. //
    ////////////////////////

    const container = ref.container;
    const scrollbar = ref.scrollbar;
    const button_prev = ref.button_prev;
    const button_next = ref.button_next;
    const slider = new Swiper(container, {
      init: false,
      allowTouchMove: false,
      //////
      speed: options.speed,
      direction: "horizontal",
      slidesPerView: "auto",
      //////
      scrollbar: {
        el: scrollbar,
        draggable: true,
      },
      navigation: {
        prevEl: button_prev,
        nextEl: button_next,
      },
      //////
      on: {
        slideChange: () => {
          const index_slide_active = slider.realIndex;
          this.setState({
            index_active: index_slide_active + 1,
          });
        },
      },
    });

    ////////////////////////
    // Init. slider-inst. //
    ////////////////////////

    this.setState({
      inst: { slider },
      initialised: true,
    });

    //////////////////////////
    // Config. interactions //
    //////////////////////////

    const state = this.state;
    const main = state.main;
    const refs = state.refs;

    ////////////////////////////////
    // Config. eventbus responses //
    ////////////////////////////////

    eventbus.on("event_show", (args) => {
      if (this.state.initialised) {
        if (args.id === options.id) {
          slider.update();
        }
      }
    });
  }

  ////////////////////////////////////
  // Config. state-change responses //
  ////////////////////////////////////

  stateChange(changes) {
    const state = this.state;
    const options = this.options;

    const main = state.main;
    const refs = state.refs;

    const blockName = options.blockName;

    /////////////////////////////////
    /////////////////////////////////

    if ("initialised" in changes) {
      if (state.initialised) {
        const slider = state.inst.slider;
        slider.init();
      }
    }
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
}

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

export { event__slider };
