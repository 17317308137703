import { toggle_mod } from "toggle_mod";

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

function Ref(element, blockName, title, log) {
  this.element = element;
  this.name_block = blockName;
  this.name_element = title;

  /////////////////////////////////////////
  // Create ref-object w/ single element //
  /////////////////////////////////////////

  if (element != null) {
    if (element.length === undefined) {
      this.hide = () => {
        toggle_mod(this, "hidden", "add");
      };
      this.show = () => {
        toggle_mod(this, "hidden", "remove");
      };
      //////
      this.activate = () => {
        toggle_mod(this, "active", "add");
      };
      this.deactivate = () => {
        toggle_mod(this, "active", "remove");
      };
    } else {
      if (log) {
        console.warn("Invalid ref-object => ", element);
      }
    }
  } else {
    if (log) {
      console.warn("No element present in ref-object => ", element);
    }
  }
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

function create_refs(ref, blockName, log) {
  const refs = [];
  //////
  for (let [title, element] of Object.entries(ref)) {
    const ref = new Ref(element, blockName, title, log);
    refs.push(ref);
  }
  //////
  return refs;
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

export { create_refs };
