import Component from "gia/Component";
import eventbus from "gia/eventbus";
import config from "gia/config";

import { toggle_mod } from "../../utilities/toggle_mod";
import { create_refs } from "../../utilities/create_refs";
import { create_main } from "../../utilities/create_main";

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

class events extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {
      buttons: [],
      filters: [],
    };
    //////
    this.options = {
      blockName: "events",
    };
  }

  mount() {
    config.set("log", false);

    const options = this.options;
    const ref = this.ref;

    /////////////////////////////////
    // Config. main- & ref-objects //
    /////////////////////////////////

    this.setState({
      main: create_main(this.element, options.blockName),
      // refs: create_refs(ref, options.blockName, true),
    });

    //////////////////////
    // Set button-width //
    //////////////////////

    const buttons = ref.buttons;
    let width_button = 0;
    for (let button of buttons) {
      if (button.offsetHeight > width_button) {
        width_button = button.offsetHeight;
      }
    }

    this.setState({
      width_button: width_button,
    });

    /////////////////////
    // Config. filters //
    /////////////////////

    const filters = ref.filters;
    for (let filter of filters) {
      filter.addEventListener("click", () => {
        this.setState({
          filter_active: filter.dataset.filter,
        });

        eventbus.emit("filter_events", { filter: filter.dataset.filter });
      });
    }

    //////////////////////////
    // Config. interactions //
    //////////////////////////

    const state = this.state;
    const main = state.main;
    // const refs = state.refs;

    ////////////////////////////////
    // Config. eventbus responses //
    ////////////////////////////////

    eventbus.on("event", (args) => {});
  }

  ////////////////////////////////////
  // Config. state-change responses //
  ////////////////////////////////////

  stateChange(changes) {
    const state = this.state;
    const options = this.options;

    const main = state.main;
    const ref = this.ref;
    const refs = state.refs;

    const blockName = options.blockName;

    ////////////////////////////////////
    ////////////////////////////////////

    if ("width_button" in changes) {
      const elements_meta = main.element.querySelectorAll(".event__meta");
      const buttons = ref.buttons;
      //////
      for (let meta of elements_meta) {
        meta.style.paddingRight = state.width_button + "px";
      }
      for (let button of buttons) {
        button.style.width = state.width_button + "px";
      }
    }

    ////////////////////////////////////
    ////////////////////////////////////

    if ("filter_active" in changes) {
      const filters = ref.filters;
      for (let filter of filters) {
        if (filter.dataset.filter === state.filter_active) {
          filter.classList.add(blockName + "__filter_active");
        } else {
          filter.classList.remove(blockName + "__filter_active");
        }
      }
    }
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  get_ref(title) {
    return this.state.refs.find((ref) => ref.name_element === title);
  }
}

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

export { events };
