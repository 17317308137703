import Component from "gia/Component";
import eventbus from "gia/eventbus";
import config from "gia/config";

import { toggle_mod } from "../../utilities/toggle_mod";
import { create_refs } from "../../utilities/create_refs";
import { create_main } from "../../utilities/create_main";

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

class eventInfo extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {};
    //////
    this.options = {
      blockName: "event-info",
    };
  }

  mount() {
    config.set("log", false);

    const options = this.options;
    const ref = this.ref;

    /////////////////////////////////
    // Config. main- & ref-objects //
    /////////////////////////////////

    this.setState({
      main: create_main(this.element, options.blockName),
      refs: create_refs(ref, options.blockName, true),
    });

    //////////////////////////
    // Config. interactions //
    //////////////////////////

    const state = this.state;
    const main = state.main;
    const refs = state.refs;

    ////////////////////////////////
    // Config. eventbus responses //
    ////////////////////////////////

    eventbus.on("event", (args) => {});
  }

  ////////////////////////////////////
  // Config. state-change responses //
  ////////////////////////////////////

  stateChange(changes) {
    const state = this.state;
    const options = this.options;

    const main = state.main;
    const refs = state.refs;

    const blockName = options.blockName;

    ////////////////////////////////////
    ////////////////////////////////////
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  get_ref(title) {
    return this.state.refs.find((ref) => ref.name_element === title);
  }

  ////////////////////////////////////
  ////////////////////////////////////

  set_padding() {
    const siteHeader_typo = document.querySelector(".site-title__letter_s");
    const siteHeader_typo_DOMrect = siteHeader_typo.getBoundingClientRect();
    //////
    this.element.style.paddingTop = siteHeader_typo_DOMrect.height + 10 + "px";
  }
}

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

export { eventInfo };
